<template>
  <div class="landing-page-wrap landing-page-wrap_gray compare">
    <loader v-if="loader" />
    <div class="landing-page__container landing-page__container_extend">
      <breadCrumbs :items="breadCrumbs" />
      <div class="landing-page-content landing-page-content_mobile">
        <div class="services-info__wrapper services-info__wrapper_mobile">
          <div class="landing-page__heading">
            {{ $t('compare.compareOptions') }}
          </div>
          <div class="compare-info">
            <template
              v-for="itemTitle in compareInfo"
            >
              <div
                :key="itemTitle.key"
                class="compare-info-item"
              >
                <div class="compare-info-item__title">
                  {{ $t(itemTitle.text) }}
                </div>
                <div
                  v-for="variant in compareList"
                  :key="variant.id"
                  class="compare-info-wrapper"
                >
                  <div class="compare-info-wrapper__left">
                    <div class="compare-info-wrapper__image">
                      <img
                        :src="getOptionImage(variant.country.code)"
                        alt="flag"
                      >
                    </div>
                    <div class="compare-info-wrapper__text">
                      <div class="compare-info-wrapper__title">
                        {{ variant.country.name[language] }}
                      </div>
                      <div class="compare-info-wrapper__law">
                        {{ variant.managementForm[language] }}
                      </div>
                    </div>
                  </div>
                  <div class="compare-info-wrapper__right">
                    <div class="compare-info-wrapper__value">
                      <template v-if="itemTitle.type === 'Array'">
                        <span
                          v-if="variant[itemTitle.key][0] === variant[itemTitle.key][1]"
                        >
                          {{ variant[itemTitle.key][0] }}
                        </span>
                        <span
                          v-for="(item, index) in variant[itemTitle.key]"
                          v-else
                          :key="`${itemTitle.key}-${index}`"
                        >
                          {{ item }}
                        </span>
                        <template v-if="itemTitle.subText">
                          {{ calcNum(variant[itemTitle.key] || 0,
                                     [$t('global.dayOne'),$t('global.dayAlternative'), $t('global.days')])
                          }}
                        </template>
                        <div
                          v-if="!itemTitle.notCurrency"
                          class="compare-info-wrapper__currency"
                        >
                          {{ variant.currency }}
                        </div>
                      </template>
                      <template v-else-if="itemTitle.type === 'Boolean'">
                        <span>
                          {{ $t(itemTitle.value[`${variant[itemTitle.key]}`]) }}
                        </span>
                      </template>
                      <template v-else>
                        {{ (variant[itemTitle.key] || 0) }}
                        <template v-if="itemTitle.subText">
                          {{ calcNum(variant[itemTitle.key] || 0,
                                     [$t('global.dayOne'),$t('global.dayAlternative'), $t('global.days')]) }}
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="country-list">
            <div
              v-for="service in compareList"
              :key="service.id"
              class="country-item"
            >
              <div class="country-item__image">
                <img
                  :src="getOptionImage(service.country.code)"
                  alt="flag"
                >
              </div>
              <div class="country-item__title">
                {{ service.country.name[language] }}
              </div>
              <div class="country-item__law">
                {{ service.managementForm[language] }}
              </div>
              <button
                class="ub-btn ub-btn_primary country-item__btn"
                @click="goServicePage(service._id)"
              >
                {{ $t('global.choose') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/loader';
import compareInfoDef from '@/constants/characteristics';
import breadCrumbs from '@/components/landing/breadCrumbs';

export default {
  name: 'Compare',
  components: {
    loader,
    breadCrumbs,
  },
  props: {
    compareList: {
      type: Array,
      default: () => [],
    },
    breadCrumbs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loader: false,
      compareInfo: compareInfoDef,
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    calcNum(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    goServicePage(id) {
      this.$router.push({
        name: `service-${this.language}`,
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";
@import "../sass/mixins";

.landing-page-wrap {
  padding-bottom: 30px;
}

.compare {

  &__wrapper {
    background: $color-white;
    padding: 23px 16px 21px 16px;
    margin-top: 52px;
  }

  .landing-page__heading {
    margin-bottom: 30px;
    text-align: center;
    font-size: $font-size-xlg;
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    &-item {
      width: 100%;

      & + .compare-info-item {
        margin-top: 25px;
      }

      &__title {
        text-align: center;
        color: $color-dodger-blue;
        font: $font-size-base $font-landing-medium;
        margin-bottom: 7px;
      }
    }

    &-wrapper {
      display: flex;

      & + .compare-info-wrapper {
        border-top: 1px solid $color-alabaster;
      }

      &:last-child {
        border-bottom-color: transparent;
      }

      &__image {
        max-width: 18px;
        min-width: 18px;
        height: 18px;
        margin-right: 8px;
        background: red;
        border-radius: 50%;

        img {
          border-radius: 50%;
        }
      }

      &__left {
        width: 60%;
        display: flex;
        border-right: 1px solid $color-alabaster;
        padding: 10px 20px 10px 0;
        min-height: 53px;

        @include mobile {
          width: 50%;
        }
      }

      &__right {
        width: 40%;
        padding: 10px 0 10px 20px;

        @include mobile {
          width: 50%;
        }
      }

      &__currency {
        display: inline-block;
      }

      &__value {
        font: $font-size-landing-small $font-landing-global;

        span + span {
          display: inline-block;
          position: relative;
          margin-right: 3px;

          &:before {
            content: '-';
            position: relative;
            left: 0;
          }

          &:nth-child(3) {
            margin-left: 2px;

            &:before {
              content: '';
            }
          }
        }
      }

      &__title {
        font: $font-size-sm $font-landing-heavy;
      }

      &__law {
        font: $font-size-sm $font-landing-global;
      }
    }
  }
}

.country {
  &-list {
    display: flex;
    flex-direction: column;
  }

  &-item {
    border: 1px solid $color-gallery;
    padding: 20px;
    max-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & + .country-item {
      margin-top: 13px;
    }

    &__image {
      max-width: 56px;
      min-width: 56px;
      height: 56px;
      margin: auto auto 15px auto;

      img {
        border-radius: 50%;
      }
    }

    &__title {
      font: $font-size-landing-small $font-landing-heavy;
      text-align: center;
      margin-bottom: 1px;
    }

    &__law {
      font: $font-size-landing-small $font-landing-global;
      text-align: center;
      margin-bottom: 14px;
    }

    &__btn {
      margin: auto;
      height: 53px;
      width: 121px;
      border-radius: $borderRadiusLarger;
      font-family: $font-landing-medium;
    }
  }
}
</style>
