const compareInfoDef = [
  {
    text: 'servicesPage.addServiceModal.numberOfMembers',
    key: 'ownersCompanyAmount',
    type: 'Array',
    notCurrency: true,
  },
  {
    text: 'servicesPage.addServiceModal.ownershipAllowed',
    key: 'isOwnershipAllowed',
    type: 'Boolean',
    value: {
      true: 'global.yes',
      false: 'global.no',
    },
  },
  {
    text: 'servicesPage.addServiceModal.nonResidentDirector',
    key: 'isNonResidentDirector',
    type: 'Boolean',
    value: {
      true: 'global.yes',
      false: 'global.no',
    },
  },
];
export default compareInfoDef;
