<template>
  <div v-if="!mobileStatus">
    <transition
      name="fade-content"
      appear
      mode="out-in"
    >
      <div class="landing-page-wrap compare landing-page-wrap_gray">
        <loader v-if="loader" />
        <div class="landing-page__container landing-page__container_extend landing-page__container_compare">
          <breadCrumbs :items="breadCrumbs" />
          <div class="landing-page-content">
            <div class="services-info__wrapper">
              <div class="landing-page__heading">
                {{ $t('compare.compareOptions') }}
              </div>
              <div class="compare-info">
                <div class="compare-info__column compare-info__column_title">
                  <div
                    class="compare-info__cell"
                  >
                  </div>
                  <div
                    v-for="itemTitle in compareInfo"
                    :key="itemTitle.id"
                    class="compare-info__cell"
                  >
                    {{ $t(itemTitle.text) }}
                  </div>
                </div>
                <VuePerfectScrollbar class="compare__list">
                  <div
                    v-for="variant in compareList"
                    :key="variant.id"
                    class="compare-info__services"
                  >
                    <div
                      class="compare-info__column"
                    >
                      <div
                        class="compare-info__cell"
                      >
                        <div
                          class="compare-info-service"
                        >
                          <div class="compare-info-service__image">
                            <img
                              :src="getOptionImage(variant.country.code)"
                              alt="flag"
                            >
                          </div>
                          <div class="compare-info-service__title">
                            {{ variant.country.name[language] }}
                          </div>
                          <div class="compare-info-service__description">
                            {{ variant.managementForm[language] }}
                          </div>
                        </div>
                      </div>
                      <template
                        v-for="itemTitle in compareInfo"
                      >
                        <div
                          :key="itemTitle.key"
                          class="compare-info__cell"
                        >
                          <template v-if="itemTitle.type === 'Array'">
                            <span
                              v-if="variant[itemTitle.key][0] === variant[itemTitle.key][1]"
                            >
                              {{ variant[itemTitle.key][0] }}
                            </span>
                            <span
                              v-for="(item, index) in variant[itemTitle.key]"
                              v-else
                              :key="`${itemTitle.key}-${index}`"
                            >
                              <span
                                v-if="index === 0 && itemTitle.min"
                                class="compare-info__cell_min"
                              >
                                {{ $t('global.min') }}
                              </span>
                              <span
                                v-if="index === 1 && itemTitle.max"
                                class="compare-info__cell_max"
                              >
                                {{ $t('global.max') }}
                              </span>
                              {{ item }}
                            </span>
                            <template v-if="itemTitle.subText">
                              <div>
                                {{ calcNum(variant[itemTitle.key] || 0,
                                           [$t('global.dayOne'),$t('global.dayAlternative'), $t('global.days')]) }}
                              </div>
                            </template>
                            <div v-if="!itemTitle.notCurrency">
                              {{ variant.currency }}
                            </div>
                          </template>
                          <template v-else-if="itemTitle.type === 'Boolean'">
                            <span>
                              {{ $t(itemTitle.value[`${variant[itemTitle.key]}`]) }}
                            </span>
                          </template>
                          <template v-else>
                            {{ (variant[itemTitle.key] || 0) }}
                            <template v-if="itemTitle.subText">
                              {{ calcNum(variant[itemTitle.key] || 0,
                                         [$t('global.dayOne'),$t('global.dayAlternative'), $t('global.days')]) }}
                            </template>
                          </template>
                        </div>
                      </template>
                      <button
                        class="ub-btn ub-btn_primary compare-info__btn"
                        @click="goServicePage(variant._id)"
                      >
                        {{ $t('global.choose') }}
                      </button>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <compareMobile
      :compareList="compareList"
      :breadCrumbs="breadCrumbs"
    />
  </div>
</template>

<script>
import loader from '@/components/loader';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import serviceApi from '@/api/services/servicesApi';
import compareInfoDef from '@/constants/characteristics';
import queryString from 'query-string';
import breadCrumbs from '@/components/landing/breadCrumbs';
import compareMobile from '@/pages/compareMobile';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Compare',
  components: {
    loader,
    VuePerfectScrollbar,
    breadCrumbs,
    compareMobile,
  },
  data() {
    return {
      loader: false,
      compareInfo: compareInfoDef,
      compareList: [],
    };
  },
  computed: {
    mobileStatus() {
      const md = new MobileDetect(window.navigator.userAgent);
      const mobile = md.mobile();
      const tablet = md.tablet();
      return mobile !== null || tablet !== null;
    },
    breadCrumbs() {
      return [
        { name: this.$t('breadScrums.mainPage'), route: `/${this.language}/` },
        { name: this.$t('breadScrums.selectionService'), route: `/${this.language}/selection-service` },
        { name: this.$t('breadScrums.compareVariables'), route: `/${this.language}/compare` },
      ];
    },
    language() {
      return this.$i18n.locale;
    },
  },
  beforeMount() {
    const { location } = window;
    const parsed = queryString.parse(location.search);
    const {
      compare = [],
    } = parsed;
    if (compare.length) {
      const page = 1;
      const perPage = 20;
      const filterQuery = JSON.stringify({ _id: compare });
      this.loader = true;
      serviceApi.getServicesList({ page, perPage, filter: filterQuery })
        .then((resp) => {
          this.loader = false;
          this.compareList = resp.data.resource;
        })
        .catch((err) => {
          this.loader = false;
          console.error(err);
        });
    } else {
      this.$router.push({
        name: `selectionService-${this.language}`,
      });
    }
  },
  methods: {
    calcNum(number, time) {
      const cases = [2, 0, 1, 1, 1, 2];
      return time[(number % 100 > 4 && number % 100 < 20)
        ? 2
        : cases[(number % 10 < 5)
          ? number % 10 : 5]
      ];
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/country/${image}.svg`);
        /* eslint-enable */
      } catch (error) {
        return false;
      }
    },
    goServicePage(id) {
      this.$router.push({
        name: `service-${this.language}`,
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/variables";

.compare {

  &__wrapper {
    background: $color-white;
    padding: 27px 16px 21px 16px;
    box-shadow: 0 0 8px rgba($color-black, .04);
    border-radius: $borderRadius;
    margin-top: 52px;
  }

  .landing-page__heading {
    margin-bottom: 32px;
  }

  &__list {
    display: flex;
    max-width: 783px;
    padding-bottom: 20px;
  }

  &-info {
    display: flex;

    &-service {
      &__image {
        margin-bottom: 5px;

        img {
          width: 33px;
          height: 33px;
          border-radius: 50%;
        }
      }

      &__title {
        font: $font-size-md $font-landing-heavy;
        color: $color-mine-shaft;
        margin-bottom: 2px;
      }

      &__description {
        font: $font-size-md $font-landing-global;
        color: $color-mine-shaft;
      }
    }

    &__btn {
      margin-left: 40px;
      margin-top: 14px;
      font-family: $font-landing-medium;
      border-radius: $borderRadiusLarger;
      height: 53px;
      width: 121px;
    }

    &__cell {
      min-height: 73px;
      max-height: 73px;
      display: flex;
      align-items: center;
      font-family: $font-landing-global;

      div {
        margin-left: 2px;
      }

      span {
        span {
          display: inline-block;
          margin-right: -3px;
        }
      }

      &_min {
        margin-right: 0 !important;
        font-weight: bold;
      }

      &_max {
        margin-right: 2px !important;
        margin-left: 2px !important;
        font-weight: bold;
      }

      span + span {
        display: inline-block;
        position: relative;
        margin-right: 3px;

        &:before {
          content: '-';
          position: relative;
          left: 1px;
        }

        &:nth-child(3) {
          margin-left: 2px;

          &:before {
            content: '';
          }
        }
      }

      &:first-child {
        min-height: 95px;
      }

      & + .compare-info__cell {
        border-top: 1px solid $color-alabaster;
      }
    }

    &__column {
      max-width: 335px;
      width: 100%;

      &_title {
        .compare-info__cell {
          color: $color-dodger-blue;
          font: $font-size-base $font-landing-medium;
        }
      }
    }

    &__services {
      max-width: 265px;
      min-width: 265px;
      width: 100%;
      border-left: 1px solid $color-alabaster;

      .compare-info__cell {
        padding: 0 25px 0 40px;

        &:first-child {
          min-height: 95px;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
